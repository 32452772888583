@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;

@mixin thumbnail-articles {
  @apply flex;
  @apply flex-wrap;

  @screen md {
    @apply mx-[-8px];
  }
  @screen lmd {
    @apply ml-[-33px];
  }
  @screen lg {
    @apply mx-[-10px];
  }

  [class*='articleAnnexCard_drive-annex-cards__wrapper'] {
    @apply w-full;
    @apply mb-4;

    &:nth-of-type(1n + 7) {
      @apply hidden;
    }

    @screen md {
      max-width: calc(100% / 2);
      @apply px-2;
      @apply mb-[18px];
    }

    @screen lmd {
      @apply mb-[22px];
      @apply px-[33px];
    }

    @screen lg {
      max-width: calc(100% / 3);
      @apply px-[10px];
      &:nth-of-type(1n + 7) {
        @apply block;
      }
    }
  }

  [class*='articleAnnexCard_drive-annex-cards__container'] {
    @apply mb-4;
    @apply h-[84px];
    @screen md {
      @apply h-[80px];
    }
    @screen lmd {
      @apply h-[99px];
    }

    &:hover {
      [class*='articleAnnexCard_drive-annex-cards__heading'] {
        @apply text-blue-dark;
      }
    }
  }

  [class*='subtexts_drive-annex-card__subtext-date'] {
    @include font-caption('desktop', 'normal');
    @apply text-black;
    @apply italic;
  }

  [class*='articleCard_drive-article-card__comment__wrapper'] {
    img {
      @apply w-[13px];
      @apply h-[13px];
    }

    span {
      @apply text-black;
      @include font-caption('mobile', 'bold');
    }
  }

  [class*='articleAnnexCard_drive-annex-cards__content'] {
    @apply pr-5;
    @apply pt-0;

    @screen md {
      @apply pr-0;
    }
  }

  [class*='articleAnnexCard_drive-annex-cards__heading'] {
    @include font-subtext('mobile', 'medium');
    @include truncate(3);
  }
}

/**
  usage: 
  - works for showroom article sliders and home page article sliders.
  - apply to parent of div which wraps glideSlider_glide
*/
@mixin rightEdgedArticleSlides {
  // screenwidth - left margin
  width: calc(100vw - 20px);
  @screen md {
    width: calc(100vw - 56px);
  }
  @screen lg {
    //derived from 100vw - (100vw - 1024px)/2, 1024 is the normal container width
    width: calc(50vw + 512px);
  }
  @screen xl {
    @apply w-full;
  }

  [class^='navigation_glide__arrows'] {
    width: calc(100vw - 20px * 2);
    @screen md {
      width: calc(100vw - 56px * 2);
    }
    @screen lg {
      @apply w-[1024px];
    }
    @screen xl {
      @apply w-full;
    }
  }

  [class*='navigation_glide__arrow--left'] {
    @apply left-2;

    //make sure arrow is not right at the edge of screen
    @screen lg {
      @apply ml-4;
    }
    @media screen and (min-width: 1050px) {
      @apply ml-0;
    }
  }
  [class*='navigation_glide__arrow--right'] {
    @apply right-2;

    //make sure arrow is not right at the edge of screen
    @screen lg {
      @apply mr-4;
    }
    @media screen and (min-width: 1050px) {
      @apply mr-0;
    }
  }
  [class*='navigation_glide__bullets'] {
    @apply -left-2.5;
    @screen md {
      @apply -left-7;
    }
    @screen lg {
      @apply left-0;
    }
  }
}

@mixin set-width($width) {
  width: $width;
  min-width: $width;
  max-width: $width;
}

/**
  usage: 
  - works for YMAL slides in single archive pages
  - apply to parent of div which wraps glideSlider_glide
*/
@mixin rightEdgedYMALSlides {
  --slider-width: calc(100vw - 20px);
  @apply ml-grid2s; //20px is grid2s
  width: var(--slider-width);
  @screen lg {
    //derived from 100vw - (100vw - 1024px)/2 - grid2s
    --slider-width: calc(50vw + 492px);
  }
  @screen xl {
    @apply ml-0;
    @apply px-grid2s;
    @apply w-full;
  }
  [class^='articlesTitle_drive-articles-title'] {
    @apply max-w-[984px];
    @screen xl {
      @apply max-w-full;
    }
  }
  [class^='navigation_glide__arrows'] {
    @apply max-w-[984px];
    @apply mr-5;
    @screen xl {
      @apply max-w-full;
      @apply mr-0;
    }
  }
  [class*='navigation_glide__arrow--left'] {
    @apply left-2;
  }
  [class*='navigation_glide__arrow--right'] {
    @apply right-2;
  }
  [class*='navigation_glide__bullets'] {
    transform: translate(-10px, 40px);
    @screen lg {
      @apply w-[984px];
      transform: translate(0px, 40px);
      @apply m-0; //to remove margin auto center from default.
    }
    @screen xl {
      @apply w-full;
    }
  }

  [class*='glideSlider_glide__slides--not-mounted'] {
    > div {
      --gap: 16px;
      &:not(:first-child) {
        margin-left: var(--gap);
      }
      @include set-width(286px);
      @media (min-width: 400px) {
        --gap: 20px;
        @include set-width(318px);
      }
      @screen sm {
        @include set-width(calc((100vw - 90px) / 2));
      }
      @media (min-width: 701px) {
        @include set-width(calc((100vw - 100px) / 2));
      }
      @media (min-width: 861px) {
        @include set-width(calc((100vw - 140px) / 2));
      }
      @media (min-width: 921px) {
        @include set-width(calc((100vw - 160px) / 2));
      }
      @media (min-width: 1025px) {
        @include set-width(calc((var(--slider-width) - 90px) / 3));
      }
      @screen xl {
        @include set-width(370px);
      }
    }
  }
}
