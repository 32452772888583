@use '/styles/mixin/fonts.module.scss' as *;

.drive-home-cfs {
  @apply w-auto;
  @apply flex-wrap;
  @apply flex-col;

  @screen md {
    @apply max-w-100;
    @apply justify-between;
    @apply flex-row;
  }

  &__link {
    @apply inline-flex;
    @apply mb-4;
    @apply text-blue-dark;
    @include font-subtext('mobile', 'medium');

    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }

  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply items-center;
    @apply flex-col;

    @screen md {
      @apply justify-between;
      @apply flex-row;
    }
  }

  &__bottom-link {
    @apply float-right;
    @apply ml-auto;
    width: fit-content;
    @include font-subtext('mobile', 'medium');
    @apply mt-3;
    @screen md {
      @apply hidden;
    }
  }
}

.drive-home-side-articles {
  &__title-link {
    @apply inline-flex;
  }

  &__articles {
    @apply justify-center;

    @screen sm {
      @apply justify-between;
    }
  }

  &__link {
    @apply hidden;
    @apply self-end;
    @apply mt-2;

    @screen xl {
      @apply flex;
    }
  }
}
