@use '/styles/mixin/fonts.module.scss' as *;
.drive-cfs__cars {
  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-center;
    @apply mb-6;
    @screen lg {
      @apply px-0;
    }
  }
  &__header {
    @apply w-full;
  }
  &__title {
    @apply mb-4;
    @include font-heading('mobile', 'h3');
    @apply font-semibold;
    @screen md {
      @include font-heading('desktop', 'h3');
    }
  }
  &__title-compact {
  }
  &__header-link {
    @apply text-blue-dark;
  }
  &__thumbnail {
    @apply flex;
    @apply flex-col;
    @apply justify-center;
  }
  &__thumbnail-wrapper {
    @apply w-full;
    @apply grid;
    @apply justify-center;
  }
  &__thumbnail-horizontal {
    @apply grid-rows-1;
    @apply gap-1;
    @screen md {
      @apply grid-cols-3;
      @apply gap-4;
    }
  }
  &__link-wrapper {
    @apply w-full;
    @apply mt-2;
    @apply mr-10;
    @screen xs {
      @apply mr-0;
    }
  }
  &__link {
    [class^='link_drive-link__external__text'] {
      @include font-subtext('mobile', 'medium');
      @screen md {
        @include font-subtext('desktop', 'medium');
      }
    }
  }
}
