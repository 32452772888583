/** 
This file contains font mixin used across project. 
Figma Reference : https://www.figma.com/file/6vDaMx7zYK4R87b1anOczT/Mustang-%E2%80%93-Sign-off-font-change?node-id=6385%3A0
All mixin uses primary font as default, we should overwrite font at component level if needed. 
*/

/** Device types */
$device-desktop: 'desktop';
$device-mobile: 'mobile';

@mixin font-heading($device: $device-desktop, $type: 'h2') {
  // Types
  $h1: 'h1';
  $h2: 'h2';
  $h3: 'h3';
  $h4: 'h4';

  // Desktop font style
  @if $device == $device-desktop {
    @if $type == $h1 {
      //styleName: H1 - desk;
      @apply text-3-xlarge;
      @apply not-italic;
      @apply font-semibold;
      line-height: 58px;
      @apply tracking-normal;
    }

    @if $type == $h2 {
      //styleName: H2 Sans Serif - desk;
      @apply text-xlarge;
      @apply not-italic;
      @apply font-semibold;
      line-height: 42px;
      @apply tracking-normal;
    }

    @if $type == $h3 {
      //styleName: H3 SemiBold - desk;
      @apply text-2-large;
      @apply not-italic;
      @apply font-semibold;
      line-height: 32px;
      @apply tracking-normal;
    }

    @if $type == $h4 {
      //styleName: H4 - desk;
      @apply text-3-xmedium;
      @apply not-italic;
      @apply font-semibold;
      line-height: 24px;
      @apply tracking-normal;
    }
  }

  // Mobile font style
  @if $device == $device-mobile {
    @if $type == $h1 {
      //styleName: H1 - mob;
      @apply text-2-xlarge;
      @apply not-italic;
      @apply font-semibold;
      line-height: 40px;
      @apply tracking-normal;
    }

    @if $type == $h2 {
      //styleName: H2 San Serif - mob;
      @apply text-3-large;
      @apply not-italic;
      @apply font-semibold;
      line-height: 32px;
      @apply tracking-normal;
    }

    @if $type == $h3 {
      //styleName: H3 SemiBold - mob;
      @apply text-large;
      @apply not-italic;
      @apply font-semibold;
      line-height: 30px;
      @apply tracking-normal;
    }

    @if $type == $h4 {
      //styleName: H4 - mob;
      @apply text-3-xmedium;
      @apply not-italic;
      @apply font-semibold;
      line-height: 26px;
      @apply tracking-normal;
    }
  }
}

@mixin font-standfirst($device: $device-desktop) {
  // Desktop font style
  @if $device == $device-desktop {
    //styleName: Standfirst - desk;
    @apply text-3-xmedium;
    @apply not-italic;
    @apply font-semibold;
    line-height: 30px;
    @apply tracking-normal;
  }

  // Mobile font style
  @if $device == $device-mobile {
    //styleName: Standfirst - mob;
    @apply text-2-xmedium;
    @apply not-italic;
    @apply font-semibold;
    line-height: 24px;
    @apply tracking-normal;
  }
}

@mixin font-subtext($device: $device-desktop, $type: 'large') {
  // Types
  $large: 'large';
  $medium: 'medium';
  $small: 'small';

  // Desktop font style
  @if $device == $device-desktop {
    @if $type == $large {
      //styleName: Sub 1 - desk;
      @apply text-2-xmedium;
      @apply not-italic;
      @apply font-semibold;
      line-height: 22px;
      @apply tracking-normal;
    }

    @if $type == $medium {
      //styleName: Sub 2 - desk;
      @apply text-medium;
      @apply not-italic;
      @apply font-semibold;
      line-height: 24px;
      @apply tracking-normal;
    }

    @if $type == $small {
      //styleName: Sub 3 - desk;
      font-size: 11px; // TODO: Fonts Mixin
      @apply not-italic;
      @apply font-semibold;
      line-height: 16px;
      @apply tracking-normal;
    }
  }

  // Mobile font style
  @if $device == $device-mobile {
    @if $type == $large {
      //styleName: Sub 1 - mob;
      @apply text-medium;
      @apply not-italic;
      @apply font-semibold;
      line-height: 20px;
      @apply tracking-normal;
    }

    @if $type == $medium {
      //styleName: Sub 2 - mob;
      @apply text-3-xsmall;
      @apply not-italic;
      @apply font-semibold;
      line-height: 20px;
      @apply tracking-normal;
    }

    @if $type == $small {
      //styleName: Sub 3 - mob;
      @apply text-tiny;
      @apply not-italic;
      @apply font-semibold;
      line-height: 14px;
      @apply tracking-normal;
    }
  }
}

@mixin font-text($device: $device-desktop, $type: 'large') {
  // Types
  $large: 'large';
  $medium: 'medium';
  $small: 'small';

  // Desktop font style
  @if $device == $device-desktop {
    @if $type == $large {
      //styleName: Body 1 - desk;
      @apply text-2-xmedium;
      @apply not-italic;
      @apply font-normal;
      line-height: 27px;
      @apply tracking-normal;
    }

    @if $type == $medium {
      //styleName: Body 2 - desk;
      @apply text-medium;
      @apply not-italic;
      @apply font-normal;
      line-height: 24px;
      @apply tracking-normal;
    }

    @if $type == $small {
      //styleName: Body 3 - desk;
      @apply text-3-xsmall;
      @apply not-italic;
      @apply font-normal;
      line-height: 20px;
      @apply tracking-normal;
    }
  }

  // Mobile font style
  @if $device == $device-mobile {
    @if $type == $large {
      //styleName: Body 1 - mob;
      @apply text-medium;
      @apply not-italic;
      @apply font-normal;
      line-height: 24px;
      @apply tracking-normal;
    }

    @if $type == $medium {
      //styleName: Body 2 - mob;
      @apply text-3-xsmall;
      @apply not-italic;
      @apply font-normal;
      line-height: 21px;
      @apply tracking-normal;
    }

    @if $type == $small {
      //styleName: Body 3 - mob;
      @apply text-small;
      @apply not-italic;
      @apply font-normal;
      line-height: 18px;
      @apply tracking-normal;
    }
  }
}

@mixin font-caption($device: $device-desktop, $type: 'bold') {
  // Types
  $caption-bold: 'bold';
  $caption-normal: 'normal';

  // Desktop font style
  @if $device == $device-desktop {
    @if $type == $caption-bold {
      //styleName: Caption Heading - desk;
      @apply text-2-xsmall;
      @apply not-italic;
      @apply font-semibold;
      line-height: 15px;
      @apply tracking-normal;
    }

    @if $type == $caption-normal {
      //styleName: Caption Medium - desk;
      @apply text-2-xsmall;
      @apply not-italic;
      @apply font-medium;
      line-height: 15px;
      @apply tracking-normal;
    }
  }

  // Mobile font style
  @if $device == $device-mobile {
    @if $type == $caption-bold {
      //styleName: Caption Heading - mob;
      @apply text-small;
      @apply not-italic;
      @apply font-semibold;
      line-height: 16px;
      @apply tracking-normal;
    }

    @if $type == $caption-normal {
      //styleName: Caption Medium - mob;
      @apply text-small;
      @apply not-italic;
      @apply font-medium;
      line-height: 16px;
      @apply tracking-normal;
    }
  }
}

@mixin font-button($device: $device-desktop) {
  // Desktop font style
  @if $device == $device-desktop {
    //styleName: Button;
    @apply text-medium;
    @apply not-italic;
    @apply font-semibold;
    line-height: 18px;
    @apply tracking-normal;
  }

  // Mobile font style
  @if $device == $device-mobile {
    @apply text-medium;
    @apply not-italic;
    @apply font-semibold;
    line-height: 24px;
    @apply tracking-normal;
  }
}

@mixin font-label($device: $device-desktop) {
  // Desktop font style
  @if $device == $device-desktop {
    //styleName: Labels;
    @apply text-xsmall;
    @apply not-italic;
    @apply font-bold;
    line-height: 15px;
    padding-top: 5px;
    @apply tracking-normal;
    span {
      line-height: 15px;
    }
  }

  // Mobile font style
  @if $device == $device-mobile {
    @apply text-xsmall;
    @apply not-italic;
    @apply font-bold;
    line-height: 15px;
    padding-top: 5px;
    span {
      line-height: 15px;
    }
  }
}

@mixin font-pullquote($device: $device-desktop) {
  // Desktop font style
  @if $device == $device-desktop {
    // Pullquote - desk XL
    @apply text-xlarge; //32px
    @apply not-italic;
    @apply font-medium; //500
    line-height: 40px;
  }

  // Mobile font style
  @if $device == $device-mobile {
    // Pullquote - mob L
    @apply text-large; //22px
    @apply not-italic;
    @apply font-semibold; //600
    line-height: 32px;
  }
}
