@use '/styles/mixin/fonts.module.scss' as *;
.drive-multi-car-specs {
  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply items-start;
    @apply pt-4;
    @apply px-2;
  }
  &__variant-heading {
    @apply text-center;
    @apply w-full;
  }
  &__link-wrapper {
    @apply w-full;
    @apply flex;
    @apply flex-wrap;
    @apply flex-col;
    @apply mt-1;
    @screen md {
      @apply items-center;
      @apply flex-row;
      @apply justify-between;
    }
  }

  &__link {
    @apply min-w-56;
    @apply flex;
    @apply justify-end;
    @apply border-b;
    @apply pb-4;
    @apply my-4;
    @include font-subtext('desktop', 'medium');
    @screen md {
      @apply justify-start;
      @apply border-0;
      @apply pb-0;
    }
  }
  &__linked-guides {
    @include font-button('desktop');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__heading-wrapper {
    @apply border-b;
    @apply border-gray-300;
    @apply w-full;
  }
  &__heading {
    @include font-heading('mobile', 'h2');
    @screen md {
      @include font-heading('desktop', 'h2');
    }
  }
}
