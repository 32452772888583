@use '/styles/mixin/fonts.module.scss' as *;
/* This is used only for this component styles
 * @import any common tailwind grid styles if you want to use its variables, mixins, etc
 * keep @drive namespace
 * dummy styles - purely for example demonstration purposes only
 */
.drive-shortcut-card-cfs {
  &__wrapper {
    @apply min-w-72;
    @apply min-h-136;
    @apply flex;
    @apply flex-1;
    @apply flex-col;
    @apply items-stretch;
    @apply bg-blue-normal;
    @apply text-white;
    @apply pt-6;
    @apply px-6;
    @apply pb-8;
    @apply max-w-80;
    @screen md {
      max-height: 37.5rem; // 600 px
      max-width: 19.125rem; // 306 px
    }
    &__title {
      @apply max-w-238;
      @apply m-auto;
      @apply mt-0;
      @apply mb-12;
      @apply text-center;
      @include font-text('mobile', 'large');
      @screen md {
        @include font-text('desktop', 'medium');
      }
    }
    &__select-container {
      @apply px-4;
      @apply flex;
      @apply flex-col;
      @apply items-center;
    }
    &__filters-container {
      @apply w-60;
      @apply flex;
      @apply justify-around;
      @apply mt-4;
      @apply font-semibold;
      @apply px-8;
      &__blue-btn {
        @apply bg-blue-dark;
      }
      &__white-btn {
        @apply bg-white;
        @apply text-black;
      }
      button {
        @apply flex;
        @apply items-center;
        @apply justify-center;
        @apply cursor-pointer;
        @apply rounded-r-full;
        @apply rounded-l-full;
        @apply min-w-10;
        @apply px-3;
        @apply py-2;
        @apply text-xs;
        @apply font-semibold;
        @apply leading-4;
      }
    }
    &__filter-option {
      @include font-subtext('mobile', 'medium');
    }
    &__image_container {
      @apply flex;
      @apply flex-col;
      @apply items-center;
      @apply mt-12;
      a {
        @apply cursor-pointer;
      }
      figure {
        @apply max-h-40;
        @apply flex;
        @apply mt-4;
      }
    }
    &__button-container {
      @apply flex;
      @apply justify-center;
      @apply mt-6;
    }

    &__button {
      @include font-button;
      @apply max-w-210;
      @apply normal-case;
      @apply bg-white;
      @apply text-center;
      @apply text-black;
      @apply py-3;
      @apply px-6;
      @apply w-full;
      @apply rounded-lg;
      &:hover {
        @apply text-black;
      }
    }
  }
}

.drive-shortcut-card {
  @apply flex;
  @apply flex-wrap;
  @apply justify-center;
  @apply ml-0;
  @screen md {
    @apply justify-start;
    @apply max-w-[592px];
    @apply m-auto;
  }
  :global(.dropdown) {
    @apply shadow-none;

    :global(.heading) {
      @apply bg-blue-normal;
    }
  }
}

.drive-showroom-car-types {
  &__wrapper {
    @apply min-w-72;
    @apply flex;
    @apply flex-1;
    @apply flex-col;
    @apply items-stretch;
    @apply bg-blue-dark;
    @apply text-white;
    @apply pt-6;
    @apply px-5;
    @apply h-1/2;
  }

  &__heading {
    @apply mx-auto;
    @apply mt-0;
    @apply mb-10;
    @apply text-center;
    max-width: 13.125rem;
    @include font-text('desktop', 'medium');
  }
  &__subheading {
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__items-container {
    @apply flex;
    @apply flex-col;
    @apply justify-between;
    @apply h-full;
  }

  &__items {
    @apply flex;
    @apply flex-wrap;
    @apply overflow-hidden;
  }
  &__item {
    @apply w-1/4;
    @apply px-1;
    @apply my-1;
    @apply mb-8;
    @apply overflow-hidden;
  }
  &__item-link {
    @apply flex;
    @apply flex-col;
    @apply items-center;
    @apply justify-end;
    @apply text-sm;
    @apply text-center;
    @apply text-white;
    @apply capitalize;
  }
  &__item-icon {
    @apply text-3xl;
    @apply fill-current;
  }
  &__item-label {
    @apply mt-3;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-caption('desktop');
    }
  }
  &__separator {
    @apply flex-1;
    @apply min-w-18;
  }
}

.drive-showroom-ad {
  &__content {
    @apply flex;
    @apply flex-col;
    @apply items-center;

    &-spacing {
      @apply mt-6;
    }
  }

  &__wrapper {
    @apply mx-auto;
    @apply flex;
    @apply items-start;
    @apply w-72;
  }

  &__heading {
    @apply mx-auto;
    @apply pt-6;
    @apply text-center;
    @include font-text('desktop', 'medium');
    max-width: 13.125rem; // 210 px
  }
  &__subheading {
    @include font-subtext('desktop', 'medium');
  }

  &__container {
    @apply bg-blue-dark;
    @apply max-w-80;
    max-height: 18.5rem; // 296 px
    @apply h-1/2;
    @screen md {
      max-width: 19.125rem; // 306 px
    }
  }
}

.drive-showroom-card {
  &__wrapper {
    @apply min-w-72;
    @apply flex;
    @apply flex-1;
    @apply flex-col;
    @apply items-stretch;
    @apply text-white;
    @apply max-w-80;

    height: 37.5rem; // 600 px
    @screen md {
      max-width: 19.125rem; // 306 px
    }
  }

  &__dcoty-ad {
    height: 38rem; // 608 px
    @screen md {
      height: 37.5rem; // 600 px
    }

    [class^='shortcutCardCfs_drive-showroom-ad__heading'] {
      @apply my-0;
    }

    [class^='shortcutCardCfs_drive-showroom-ad__container'] {
      @apply mb-2;
      @screen md {
        @apply mr-2;
        @apply mb-0;
      }
    }
    [class^='shortcutCardCfs_drive-showroom-ad__container'] {
      max-height: 21rem; // 336 px
      @apply h-full;
      @screen md {
        @apply mr-2;
      }
    }
  }
}

.drive-dcoty-ad {
  &__wrapper {
    @apply min-w-[17rem];
    @apply flex;
    @apply flex-1;
    @apply flex-col;
    @apply items-center;
    @apply text-white;
    @apply max-h-64;
    @apply mb-2;
    @apply w-80;
    @screen md {
      @apply mr-2;
      width: 17.5rem; // 280 px
    }
  }

  &__img {
    @apply w-full;
    @apply h-full;
  }
  &__img-wrapper {
    width: 15.563rem; // 249 px
    height: 6.188rem; // 99 px
    @apply mx-auto;
    @apply mb-7;
    margin-top: 2.688rem; // 43 px
  }

  &__link-wrapper {
    max-width: 12.5rem; // 200 px
    @apply bg-white;
    @apply text-center;
    @apply py-3;
    @apply px-6;
    @apply mb-9;
    @apply w-full;
    @apply rounded-lg;
  }

  &__link {
    @include font-button;
    @apply normal-case;
    @apply text-black;
    &:hover {
      @apply text-black;
    }
    @apply w-[12.5rem];
  }
}
