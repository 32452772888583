@use '/styles/mixin/fonts.module.scss' as *;
@use '/styles/mixin/truncate.module.scss' as *;

.drive-whats-on-at {
  &__wrapper {
    @apply w-full;
    @screen md {
      @apply mr-4;
      @apply min-w-[323px];
    }
    @screen lmd {
      @apply mr-6;
      @apply min-w-[445px];
    }
    @screen xl {
      @apply mr-8;
      @apply max-w-[473px];
    }
  }

  &__heading {
    @apply mt-0;
    @include font-heading('mobile', 'h4');
    @apply mb-4;
    @apply cursor-default;

    @screen md {
      @apply mb-5;
    }
    @screen lg {
      @include font-heading('desktop', 'h4');
    }
  }

  &__blocks {
    &-layout {
      @apply flex;
      @apply flex-col;
      @apply items-start;
      @apply justify-between;
    }
    &-container {
      @apply flex;
      @apply flex-row;
      @apply items-start;
      @apply justify-between;
      @apply mr-[-10px];
      @screen md {
        @apply mr-[-16px];
      }
      @screen lmd {
        @apply mr-[-22px];
      }
      @screen lg {
        @apply mr-[-25px];
      }
    }
  }

  &__block {
    @apply w-1/2;
    outline: 1px solid rgba(239, 239, 239, 1);
    @apply mb-4;
    @apply mr-[10px];

    @screen md {
      @apply mr-[16px];
    }

    @screen md {
      @apply mb-0;
    }

    @screen lmd {
      @apply mr-[22px];
    }
    @screen xl {
      @apply mr-[25px];
      @apply max-w-[224px];
    }

    &:hover {
      border: 1px solid #efefef;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);

      [class^='whatsOnAtDrive_drive-whats-on-at__block-heading'] {
        @apply text-blue-dark;
      }
    }
  }

  &__block-heading {
    &__wrapper {
      @apply h-14;
      @apply py-3;
      @apply px-[13px];
      @screen md {
        @apply h-[85px];
      }
    }
    @apply m-0;
    @include truncate(2);
    @include font-caption('mobile', 'normal');
    @apply text-black;
    @screen md {
      @include truncate(3);
      @include font-subtext('mobile', 'medium');
    }
  }

  &__block-img {
    @apply top-0;
  }

  &__block-full {
    @apply w-full;
    @apply max-w-none;
    @apply mr-0;

    @screen md {
      @apply mb-4;
    }

    @screen lmd {
      @apply mb-5;
    }
    @screen xl {
      @apply max-w-[473px];
    }

    [class^='whatsOnAtDrive_drive-whats-on-at__block-heading__wrapper'] {
      @screen md {
        @apply h-[67px];
      }
      @screen lmd {
        @apply h-[75px];
      }
      @screen xl {
        @apply h-20;
      }
    }

    [class^='whatsOnAtDrive_drive-whats-on-at__block-heading'] {
      @include truncate(2);
      @include font-subtext('mobile', 'large');
      @screen lmd {
        @include font-subtext('desktop', 'large');
      }
    }
  }
}
