@use '/styles/mixin/articles.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;

.article-slider {
  &__article-slider-container {
    @apply w-full;
    @apply mx-auto;
    @screen xl {
      max-width: auto;
    }
  }

  &__article-slider-wrapper {
    @apply flex;
    @apply flex-col;

    @screen xl {
      @apply w-full;
      @apply max-w-[1240px];
      @apply px-0;
    }
  }

  &__glide-slides {
    [class^='glideSlider_glide__track'] {
      @apply pb-8;
      //keep style the same when js disabled
      & > [class*='glideSlider_glide__slides--not-mounted'] {
        @apply flex-row;
        [class^='showroom-model-post-card-wrapper'] {
          @apply mx-2;
        }
      }
      & > [class^='glideSlider_glide__slides'] {
        //avoid chop from glid style after 1240 width
        @screen xl {
          @apply w-full #{!important};
        }
      }
    }
    :global(.article-card) {
      @apply bg-black;
      @apply h-[200px];
    }
    [class^='navigation_glide__arrows'],
    [class^='navigation_glide__bullets'] {
      @apply relative;
      @apply block;
      @screen xl {
        @apply hidden;
      }
    }
    [class*='navigation_glide__arrow'] {
      &::before {
        @apply top-6;
        @apply w-[0.625rem];
        @apply h-[0.625rem];
        @apply p-[0.25rem];
        @apply shadow-none;
        border: solid #000;
        border-width: 0 0.125rem 0.125rem 0;
      }
    }
    [class*='navigation_glide__bullet-item'] {
      @apply bg-black;
    }

    @include rightEdgedArticleSlides;

    [class^='articlesTitle_drive-articles-title__title'] {
      @include font-heading('mobile', 'h4');
      @screen lg {
        @include font-heading('desktop', 'h4');
      }
    }
    [class*='showroom-model-post-card-wrapper'] {
      @apply flex;
      @apply justify-start;

      [class='article-thumbnail animate-pulse'] figure {
        aspect-ratio: 16/9;
        @apply h-auto #{!important};
      }

      [class*='articleCard_drive-article-card__article-thumbnail'] {
        @apply h-full;
        @apply min-w-0;
        [class*='articleCard_drive-article-card__figure'] {
          aspect-ratio: 16/9;
        }
        [class*='articleCard_drive-article-card__thumbnail__content'] {
          @apply pt-6 #{!important};
        }
      }

      [class*='articleCard_drive-article-card__thumbnail__description'] {
        @apply mb-0;
      }

      [class*='articleCard_drive-article-card__thumbnail__comments'] {
        @apply mt-4;
      }

      [class*='articleCard_drive-article-card__thumbnail__title'],
      [class*='articleCard_drive-article-card__thumbnail__link'] {
        @include font-subtext('desktop', 'large');
        @apply h-[3.875rem]; //62px
        @apply m-0;
      }
      [class*='articleCard_drive-article-card__thumbnail__link-long'] {
        @apply h-[4.5rem];
      }
      [class*='articleCard_drive-article-card__thumbnail__title'] {
        @apply leading-[1.3125rem]; //reduced line-height to 21px to avoid 3 lines title chopping off.
      }
      [class*='articleCard_drive-article-card__figure'] {
        @apply h-full #{!important};
      }
      [class*='articleCard_drive-article-card__author-link'],
      [class*='share__count'] {
        @apply text-black;
      }
    }
  }

  &__article-slider-wrapper > &__header-link {
    @apply text-blue-dark;
    @apply self-baseline;
    @apply mb-4;
    @screen md {
      @apply ml-0;
      @apply mb-[22px];
    }
  }

  // article slider's bottom link
  &__bottom-link {
    @include font-subtext('mobile', 'medium');
    @apply h-6 z-10;
    @apply flex;
    @apply items-center;

    @apply self-end;

    &:hover {
      @apply underline;
      @apply text-blue-dark;
    }

    @screen md {
      @apply mr-0;
    }
    //shift closer to slider arrow or slider card
    //figma space - compoent initial space = shift space
    @apply mt-[-4px]; // 16 - 20 = -4
    @screen xl {
      @apply mt-[-16px]; // 16 - 32 = -14
    }
  }
}
