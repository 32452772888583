@use '/styles/mixin/fonts.module.scss' as *;

.drive-cfs__cars-thumbnail {
  &__img {
    @apply object-cover;
    @apply min-w-156;
    @apply w-156;
    @apply h-full;
    @screen lg {
      @apply min-w-202;
      @apply w-202;
      @apply h-113;
    }
  }
  &__wrapper {
    @apply w-full;
    @apply bg-white;
    @apply flex;
    @apply mb-2;
    @apply min-w-80;
    @apply max-w-80;
    @apply shadow;
    @apply h-113;
    &:last-of-type {
      @apply mb-0;
    }
    @screen lg {
      @apply min-w-403;
    }
  }
  &__link-wrapper {
    @apply w-156;
  }
  &__link-compact {
    @apply h-full;
  }
  &__link-desktop {
    @screen lg {
      @apply w-202;
    }
  }
  &__figure {
    @apply bg-gray-100;
    @apply h-full;
    @apply relative;
  }
  &__type-wrapper {
    @apply top-0;
    @apply left-0;
    @apply absolute;
    @apply bg-black;
    @apply pl-10px;
    @apply pr-5px;
    line-height: 15px;
  }
  &__type {
    @apply text-white;

    @apply truncate;
    padding-bottom: 10px;
    @include font-label;
  }
  &__car-name {
    @apply w-full;
    @apply flex;
    @apply flex-col;
    @apply justify-between;
  }
  &__car-name-compact {
    @apply pl-3;
    @apply pr-2;
    @apply py-2;
  }
  &__car-name-full {
    @apply pl-2;
    @apply pr-2;
    @apply py-1;
    @screen lg {
      @apply pl-14px;
    }
  }
  &__car-name-link {
    @apply text-black;
    @apply capitalize;
    @apply min-h-12;
    @include font-text('mobile', 'medium');
    @screen md {
      @include font-text('desktop', 'medium');
    }
  }
  &__car-name-link-compact {
  }
  &__car-name-link-desktop {
  }
  &__price-wrapper {
    @apply flex;
    @apply justify-between;
    @apply items-center;
    @screen lg {
      @apply justify-start;
    }
  }
  &__price {
    @apply mt-0;
    @apply mb-0;
    @include font-subtext('mobile', 'large');
    @screen md {
      @include font-subtext('desktop', 'medium');
    }
  }
  &__price-type {
    @apply text-gray-400;
    @apply mb-0;
    @apply mt-0;
    @apply mr-0.5;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-caption('desktop', 'normal');
    }
    @screen lg {
      @apply pl-2;
    }
  }
  &__location-wrapper {
    @apply flex;
    @apply text-gray-400;
  }
  &__location-icon {
    @apply fill-current;
    @apply w-3;
    @apply -mt-px;
    @apply max-h-4;
  }
  &__location {
    @apply mt-0;
    @apply mb-0;
    @apply pl-1;
    @apply pb-px;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-caption('desktop');
    }
  }
}
